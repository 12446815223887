import {City} from "../clients/clients";

export class CouponResult {
    results: Coupon[];
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;

    constructor() {
        this.results = [];
    }
}

/*export class Coupon {
    id: string;
    cpf: string;
    type: Type;
    number: string;
    serie: number;
    buyedAt: Date;
    isContested: boolean;
    createdAt: Date;
    city: City;
    totalService: number;
    totalProduct: number;
    total: number;
    dealership: Dealership;

    constructor() {
        this.dealership = new Dealership();
        this.city = new City();
    }
}*/

export class Coupon {
    id: string;
    userId: string;
    total: number;
    createdAt: Date;
    totalService: number;
    totalProduct: number;
    qty: number;
    isFinished: boolean;
    couponNfs: CouponNf[];

    constructor() {
        this.couponNfs = [];
    }
}

export class CouponNf {
    id: string;
    cpf: string;
    type: Type;
    number: string;
    city: City;
    serie: number;
    buyedAt: Date;
    isContested: boolean;
    createdAt: Date;
    totalService: number;
    totalProduct: number;
    total: number;
    dealership: Dealership;

    constructor() {
        this.dealership = new Dealership();
        this.city = new City();
        this.type = new Type();
    }
}


export class Dealership {
    id: string;
    cnpj: string;
    fantasyName: string;
    status: string;

    constructor() {
    }
}

export class Type {
    id: number;
    typeName: string;

    constructor() {
    }
}

export class LuckyNumber {
    number: string;
    serie: string;
    createdAt: Date;

    constructor() {
    }
}

export class Pincode {
    idPincode: number;
    pincode: string;
    isDeleted: boolean;
    batchCode: string;
    luckyNumber: LuckyNumber;

    constructor() {
        this.luckyNumber = new LuckyNumber();
    }
}
