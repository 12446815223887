<section class="container">

    <div class="d-flex align-items-center justify-content-between ml-1 mt-4">
        <div class="d-flex">
            <svg-icon class="svg" src="assets/svg/line-graph.svg"></svg-icon>
            <h1 class="title ml-4">Estatísticas</h1>
        </div>
    </div>

    <div class="mt-3">

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-0">Cupons cadastrados por estado</h2>
                    </div>
                    <div class="card-body p-0 pb-4">
                        <div class="row">
                            <div class="col-xl-3 col-6"
                                 *ngFor="let x of modelCouponsByState">
                                <div class="card-transparent">
                                    <div class="card-body widget-user px-4 pt-4 pb-0">
                                        <div class="text-left">
                                            <h5 class="fw-bold mb-0 text-primary" data-plugin="counterup">
                                                {{x.qty}}
                                            </h5>
                                            <p class="m-0">
                                                <strong>{{x.state}} </strong> {{x.percent | mask: 'separator.2'}}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card bg-white mt-4">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-0">Cupons</h2>
                    </div>
                    <div class="card-body p-0">
                        <div class="list-primary">
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total de usuários cadastrados</span>
                                <h2 class="list-primary-item-number text-primary"
                                    data-plugin="counterup">{{getFormatNumber(modelCouponsSummary.totalUsers)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total de usuários cadastrados sem cupom</span>
                                <h2 class="list-primary-item-number text-primary"
                                    data-plugin="counterup">{{getFormatNumber(modelCouponsSummary.totalUsersWithoutCoupon)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total de usuários cadastrados com cupom</span>
                                <h2 class="list-primary-item-number text-primary"
                                    data-plugin="counterup">{{getFormatNumber(modelCouponsSummary.totalUsersWithCoupon)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total de notas fiscais cadastradas</span>
                                <h2 class="list-primary-item-number text-primary"
                                    data-plugin="counterup">{{getFormatNumber(modelCouponsSummary.totalCoupons)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Média de cupons por usuário</span>
                                <h2 class="list-primary-item-number text-primary"
                                    data-plugin="counterup">{{modelCouponsSummary.couponByUserAverage.toFixed(3).replace('.', ',')}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 mt-4 mb-4 mb-lg-0">
                <div class="card bg-white">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-0">Usuário cadastrados por idade(nacional)</h2>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                <tr>
                                    <th>Faixa etária</th>
                                    <th>CPF's</th>
                                    <th>Cupons</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let x of modeCouponsByAge">
                                    <td>{{x.age}}</td>
                                    <td>{{getFormatNumber(x.cpf)}}</td>
                                    <td>{{getFormatNumber(x.coupon)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-0">Usuários cadastrados por sexo</h2>
                    </div>
                    <div class="card-body p-0 pb-4 js-chart-box">
                        <google-chart
                                [type]="chartTypePie"
                                [data]="chartPinCodesRegister"
                                [width]="chartWidth"
                                [height]="height">
                        </google-chart>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 mt-4">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-0">Cupons cadastrados por semana</h2>
                    </div>
                    <div class="card-body p-0 pb-4 js-chart-box">
                        <google-chart
                                [type]="chartAccessUsers.type"
                                [data]="chartAccessUsers.data"
                                [columns]="chartAccessUsers.columnNames"
                                [options]="chartAccessUsers.options"
                                [width]="chartWidth"
                                [height]="chartAccessUsers.height">
                        </google-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-6 mt-4">
            <div class="card bg-white">
                <div class="card-header d-flex align-items-center">
                    <h2 class="subTitle mb-0 ml-0">Top 10 Concessionárias</h2>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead>
                            <tr>
                                <th>Posição</th>
                                <th>Concessionária</th>
                                <th>Estado</th>
                                <th>Cidade</th>
                                <th class="text-right">Cupons Cadastrados</th>
                                <th class="text-right">Cupons Recebidos</th>
                                <th class="text-right">Redenção</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngIf="modelCouponsDealershipRank.length === 0">
                                <td class="py-5 text-center" colspan="7">Nenhum registro encontrado.</td>
                            </tr>
                            <tr *ngFor="let x of modelCouponsDealershipRank">
                                <td>{{x.pos}}</td>
                                <td>{{x.fantasyName}}</td>
                                <td>{{x.stateId}}</td>
                                <td>{{x.city}}</td>
                                <td class="text-right">{{getFormatNumber(x.qtyRegistered)}}</td>
                                <td class="text-right">{{getFormatNumber(x.qtyReceived)}}</td>
                                <td class="text-right">{{x.percent}}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 mt-4">
            <div class="card bg-white">
                <div class="card-header d-flex align-items-center">
                    <h2 class="subTitle mb-0 ml-0">Top 10 Cidades</h2>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead>
                            <tr>
                                <th class="w-10">Posição</th>
                                <th>Cidade / Estado</th>
                                <th class="w-20 text-right">Qntd de Códigos</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngIf="modelCouponsCityRank.length === 0">
                                <td class="py-5 text-center" colspan="4">Nenhum registro encontrado.</td>
                            </tr>
                            <tr *ngFor="let x of modelCouponsCityRank">
                                <td>{{x.pos}}</td>
                                <td>{{x.city}}</td>
                                <td class="text-right">{{getFormatNumber(x.qtyRegistered)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
