import { Component, OnInit } from '@angular/core';
import {User} from '../../model/user/user';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {Token} from '../../model/user/token';
import {ResponseServer} from "../../model/responseServer";

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  section = 0;
  model: User = new User();

  constructor(public router: Router,
              public  userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    this.toggleLoader(false);
  }

  nextSection(n: any) {
    this.section = n;
  }

  onForgot(p?: string) {
    if (p.length === 0) {
      super.showMessage('Informe o CPF cadastrado para recuperar a senha.', 'Atenção', 'error');
      return;
    }
    if (!this.isCPFValid(p)) {
      return;
    }
    const a: any = {cpf: p};
    this.userService.forgot(a).subscribe(
        data => {
          const result = data as ResponseServer;
          if (result.error) {
          } else {
            $('#modalLogin').modal('hide');
            super.showMessage(result.message, 'Sucesso','success');
          }
          return true;
        },
        error => this.onError(error)
    );
  }

  onLogin() {
    const arrValidateFields = [
      {value: this.model.email, text: 'Email*,<br>'},
      {value: this.model.password, text: 'Senha*.<br>'},
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
      return;
    }
    if (!super.isEMailValid(this.model.email)) {
      return;
    }
    this.userService.getAuthenticate(this.model).subscribe({
      next: data => this.saveLocalUser(data)
      , error: err => super.onError(err)
    });
  }

  saveLocalUser(data) {
    const token = data as Token;
    this.setToken(token.authorizationToken);
    this.userService.updateUserDataSource(token.userAdmin);
    this.userService.checkAuthenticated();
    this.router.navigate(['/']).then();
  }

}
