<header class="header d-flex justify-content-between align-items-center">
  <span class="d-flex d-lg-none d-flex justify-content-center align-items-center" (click)="actionNavToggle()">
    <div class="hamburger d-flex d-lg-none">
      <span class="bar"></span>
    </div>
  </span>
  <img src="assets/images/logo.png" class="d-block logo" height="50" alt="VW" />
  <div class="d-none d-lg-flex">
    <a href="#" class="d-none d-lg-flex align-items-center header__user">
      <span>Olá, <strong>{{model.name}}</strong></span>
      <!--<img src="https://via.placeholder.com/30" />-->
    </a>
    <!--<div class="header__btn">
      <a href="#" class="d-none header__btn__content">
        <span>Sistema PM</span>
      </a>
    </div>-->
    <div class="header__btn pr-0">
      <a href="javascript:void('sair');" (click)="onLogout()" class="d-none d-lg-flex header__user">
        <strong style="padding: 3px 15px 3px 3px;">Sair</strong>
      </a>
    </div>
  </div>
</header>
