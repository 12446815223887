<section class="container">

    <div class="d-flex align-items-center ml-1 mt-4">
        <svg-icon class="svg" src="assets/svg/engineer.svg"></svg-icon>
        <h1 class="title ml-4">Concessionárias</h1>
    </div>

    <div class="mt-3">

        <!--<div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>-->

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.fantasyName"
                                   required/>
                            <label for="name">Razão Social</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="document" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [dropSpecialCharacters]="false"
                                   [clearIfNotMatch]="true"
                                   [(ngModel)]="modelFilter.cnpj" required
                                   mask="00.000.000/0000-00"/>
                            <label for="document">CNPJ</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select name="promoState" id="promoState" class="form-control" [(ngModel)]="selectedState"
                                    (ngModelChange)="onCity(selectedState)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of listState" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="promoState">Estado</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="currentPage = 1; onSearch();">
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="align-middle w-8" scope="col"></th>
                        <th class="align-middle w-20" scope="col">Razão Social</th>
                        <th class="align-middle w-10" scope="col">CNPJ</th>
                        <th class="align-middle w-10" scope="col">E-mail</th>
                        <th class="align-middle w-8" scope="col">Dn</th>
                        <th class="align-middle w-8" scope="col">Ativo</th>
                        <th class="align-middle w-8" scope="col">Estado</th>
                        <th class="align-middle w-10" scope="col">Cidade</th>
                        <th class="align-middle w-10 text-right" scope="col">VWKB</th>
                        <th class="align-middle w-10 text-right" scope="col">VWFS</th>
                        <th class="align-middle w-10 text-right" scope="col">VWGL</th>
                        <th class="align-middle w-10 text-right" scope="col">VWBS</th>
                        <th class="align-middle w-10 text-right" scope="col">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="modelResult.results.length === 0">
                        <td colspan="10" class="text-center py-5">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
                        <td><span class="btn btn-sm btn-primary" *ngIf="!r.isValidated && r.hasToken"
                                  (click)="actionActivate(r.id)">Ativar</span></td>
                        <td>{{r.fantasyName}}</td>
                        <td>{{r.cnpj}}</td>
                        <td>{{r.email}}</td>
                        <td>{{r.dn}}</td>
                        <td>{{r.isValidated ? 'Sim' : 'Não'}}</td>
                        <td>{{r.stateId}}</td>
                        <td>{{r.city}}</td>
                        <td class="text-right">{{r.vwkbDistributed}} / {{r.vwkb}}</td>
                        <td class="text-right">{{r.vwfsDistributed}} / {{r.vwfs}}</td>
                        <td class="text-right">{{r.vwglDistributed}} / {{r.vwgl}}</td>
                        <td class="text-right">{{r.vwbsDistributed}} / {{r.vwbs}}</td>
                        <td class="text-right">{{r.totalDistributed}} / {{r.total}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>
