export class CouponFilter {
    begin: Date;
    end: Date;
    dealerId: string;
    number: string;
    cityId: string;
    stateId: string;
    page: number;

    constructor() {
    }
}
