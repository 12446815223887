export class User {
    id: string;
    profileId: string;
    name: string;
    email: string;
    cpf: string;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;
    password: string;

    constructor() {
    }
}
