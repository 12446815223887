export class DealershipFilter {
    name:  string;
    fantasyName:  string;
    cnpj:  string;
    state: string;
    page:  number;

    constructor() {
    }
}
