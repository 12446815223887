export class ContactFilter {
    name: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
    status: string;
    start: Date;
    end: Date;
l
    constructor() {
    }
}
