import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class ContactService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getContactList(model, page) {
        const url = `${this.BASE_URL}/contact/${page}`;
        return this.http.post(url, model);
    }

    updateContactStatus(id) {
        const url = `${this.BASE_URL}/contact/${id}`;
        return this.http.put(url, {});
    }

    updateContactSendResponse(id, model) {
        const url = `${this.BASE_URL}/contact/${id}/send-response`;
        return this.http.put(url, model);
    }
}
