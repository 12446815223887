<section class="container">

  <div class="d-flex align-items-center ml-5 mt-4">
    <svg-icon class="svg" src="assets/svg/interrogation-circle.svg"></svg-icon>
    <h1 class="title ml-4">Ajuda - </h1> <span class="subTitle ml-2">Dúvidas</span>
  </div>

  <div class="mt-3">
      <div class="card">
        <div class="card-header faq-header">
          <div class="container col-8">
            <h4 class="faq-title">Aqui voce encontra as principais duvidas que possam surgir<br>
              ao primeiro contato com nosso sistema</h4>
            <div class="position-relative w-100">
              <div class="input__svg--left">
                <svg-icon class="svg-input" src="assets/svg/search.svg"></svg-icon>
              </div>
              <input ID="txtSearch" runat="server" class="input__padding-left form-control" placeholder="Qual sua Duvída?" #search
                     (keyup)="onKey(search.value)">

            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container">
            <div class="form-group mb-6 d-xl-flex align-items-center col-xl-8">
            </div>
          </div>
          <div class="container">
            <div class="accordion js-accordion">
              <div class="accordion_item">
                <div class="accordion_item_question">
                  <a class="accordion_item_question_link" href="javascript:void('Veja mais');">
                    <span class="accordion_item_question_lbl">
                      Lorem Ipsum 123
                    </span>
                    <span class="accordion_item_question_icon clicked">
                      <svg-icon class="svg" src="assets/svg/angle-left.svg"></svg-icon>
                    </span>
                  </a>
                </div>
                <div class="accordion_item_answer">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </div>
              </div>
              <div class="accordion_item">
                <div class="accordion_item_question">
                  <a class="accordion_item_question_link" href="javascript:void('Veja mais');">
                    <span class="accordion_item_question_lbl">
                      Lorem Ipsum 456
                    </span>
                    <span class="accordion_item_question_icon clicked">
                     <svg-icon class="svg" src="assets/svg/angle-left.svg"></svg-icon>
                    </span>
                  </a>
                </div>
                <div class="accordion_item_answer">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </div>
              </div>
              <div class="accordion_item">
                <div class="accordion_item_question">
                  <a class="accordion_item_question_link" href="javascript:void('Veja mais');">
                    <span class="accordion_item_question_lbl">
                      Lorem Ipsum 789
                    </span>
                    <span class="accordion_item_question_icon clicked">
                     <svg-icon class="svg" src="assets/svg/angle-left.svg"></svg-icon>
                    </span>
                  </a>
                </div>
                <div class="accordion_item_answer">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </div>
              </div>
              <div class="accordion_item">
                <div class="accordion_item_question">
                  <a class="accordion_item_question_link" href="javascript:void('Veja mais');">
                    <span class="accordion_item_question_lbl">
                      Lorem Ipsum
                    </span>
                    <span class="accordion_item_question_icon clicked">
                     <svg-icon class="svg" src="assets/svg/angle-left.svg"></svg-icon>
                    </span>
                  </a>
                </div>
                <div class="accordion_item_answer">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </div>
              </div>

              <div class="accordion_item_notfound d-none">
                Nenhum resultado encontrado para <strong class="text-uppercase"></strong>, tente outro termo.
              </div>

              <div class="accordion_item">
                <div class="accordion_item_question">
                  <a class="accordion_item_question_link" href="javascript:void('Veja mais');">
                    <span class="accordion_item_question_lbl">
                      Lorem Ipsum 10101
                    </span>
                    <span class="accordion_item_question_icon clicked">
                     <svg-icon class="svg" src="assets/svg/angle-left.svg"></svg-icon>
                    </span>
                  </a>
                </div>
                <div class="accordion_item_answer">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat.
                </div>
              </div>
              <div class="accordion_item">
                <div class="accordion_item_question">
                  <a class="accordion_item_question_link" href="javascript:void('Veja mais');">
                    <span class="accordion_item_question_lbl">
                      Lorem Ipsum
                    </span>
                    <span class="accordion_item_question_icon clicked">
                     <svg-icon class="svg" src="assets/svg/angle-left.svg"></svg-icon>
                    </span>
                  </a>
                </div>
                <div class="accordion_item_answer">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. 999
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</section>
