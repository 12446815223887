<div class="single__page">
  <h1 class="mb-5">
    <img src="assets/images/logodark.png" class="logo" alt="VW" />
  </h1>
  <p>
    Você não está logado.
  </p>
  <p>
    <a class="btn btn-secondary" href="javascript:void('Login');" [routerLink]="['/login']">Clique aqui para <strong>ENTRAR!</strong></a>
  </p>
</div>
