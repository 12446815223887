import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {CouponService} from "../../service/coupon.service";
import {ClientService} from "../../service/client.service";
import {UserService} from "../../service/user.service";
import {CouponResult} from "../../model/coupon/coupon";
import {User} from "../../model/user/user";
import {Dealership} from "../../model/dealership/dealership";

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss']
})
export class PincodeComponent extends BaseComponent implements OnInit {

  isSearch = false;
  modelPincode = null;
  modelPincodeText = '';
  modelPincodeResult: any = {code: undefined, status: undefined, user: User, dealer: Dealership};

  constructor(public router: Router,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
  }

  onGetPincode() {
    if (this.isNullOrUndefined(this.modelPincode)) {
      this.showMessage('Atenção', 'Informe um pincode para prosseguir.', 'warning');
      return;
    }
    this.userService.getPincode(this.modelPincode).subscribe({
      next: data => {
        const a: any = data;
        this.modelPincodeText = this.modelPincode;
        this.modelPincodeResult = a;
        this.isSearch = true;
      }
    });
  }

}
