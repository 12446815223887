<section class="container">

    <div class="d-flex align-items-center ml-1 mt-4">
        <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
        <h1 class="title ml-4">Pincode</h1>
    </div>

    <div class="mt-3">
        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelPincode" required />
                            <label for="name">Número</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="onGetPincode();"
                        >
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white py-5 text-center" *ngIf="isSearch">
            <h2 [ngClass]="{
                'text-danger' : modelPincodeResult.status === 'invalid',
                'text-success' : modelPincodeResult.status === 'available',
                'text-info' : modelPincodeResult.status === 'registered'
            }">
                <small>Pincode</small><br/>
                {{modelPincodeText}}
            </h2>
            <h3 class="text-info" *ngIf="modelPincodeResult.status === 'registered'">Registrado</h3>
            <h4 class="text-info" *ngIf="modelPincodeResult.status === 'registered'">Usuário: {{modelPincodeResult.user.name}}</h4>
            <h4 class="text-info" *ngIf="modelPincodeResult.status === 'registered'">CPF: {{modelPincodeResult.user.cpf}}</h4>
            <h4 class="text-info" *ngIf="modelPincodeResult.status === 'registered'">Concessionária: {{modelPincodeResult.dealer.name}}</h4>
            <h3 class="text-danger" *ngIf="modelPincodeResult.status === 'invalid'">Inválido</h3>
            <h3 class="text-success" *ngIf="modelPincodeResult.status === 'available'">Disponível</h3>
        </div>

    </div>

</section>
