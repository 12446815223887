import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {User} from "../../model/user/user";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  model: User = new User();

  constructor(public router: Router,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
      }, error: err => super.onError(err)
    });
  }

  actionNavToggle(): void {
    $('body').toggleClass('nav-open');
    $('.hamburger').toggleClass('open');
  }

  onLogout() {
    this.confirmMessage('Atenção', 'Deseja realmente sair?', () => {
      localStorage.clear();
      this.userService.isAuthenticated = false;
      this.userService.updateUserDataSource(new User());
      this.router.navigate(['/login']);
    });
  }
}
