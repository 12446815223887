<div class="container">

    <div class="d-flex align-items-center justify-content-between ml-1 mt-4">
        <div class="d-flex">
            <svg-icon class="svg" src="assets/svg/user.svg"></svg-icon>
            <h1 class="title ml-4">Usuários</h1>
        </div>
        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-outline-primary mx-2 mb-3 mb-xs-0" [routerLink]="'/usuarios'">
            <!--<svg-icon class="svg-white" src="assets/svg/plus.svg"></svg-icon>-->
            Voltar
        </a>
    </div>

    <div class="mt-3">

        <!--<div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>-->

        <div class="card mt-3 bg-white mx-2">
            <div class="card-header">
                <h2 class="title">Dados Pessoais</h2>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" [(ngModel)]="model.cpf"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   type="text" maxlength="200" autocomplete="off" required />
                            <label class="disabled" for="name">CPF</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.name"
                                   required />
                            <label class="disabled" for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.email" required />
                            <label class="disabled" for="name">E-mail</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select id="profile" class="form-control" name="type" [disabled]="true">
                                <option [selected]="model.sex === 'm'">Masculino</option>
                                <option [selected]="model.sex === 'f'">Feminino</option>
                            </select>
                            <label for="profile" class="mb-0 mr-3 disabled">Sexo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="document" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [ngModel]="model.birthday | date: 'dd/MM/yyyy'"  required
                                   mask="00/00/0000"/>
                            <label class="disabled" for="document">Data de Nascimento</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="phone" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.phone" required  mask="(00) 0000-0000||(00) 00000-0000"/>
                            <label class="disabled" for="phone">Telefone</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="cel" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.cellPhone" required  mask="(00) 0000-0000||(00) 00000-0000"/>
                            <label class="disabled" for="cel">Celular</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-header">
                <h2 class="title">Endereço</h2>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="cep" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.zipcode" required mask="00000-000" />
                            <label class="disabled" for="cep">Cep</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="input-material">
                            <input id="address" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.address" required/>
                            <label class="disabled" for="address">Endereço</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2">
                        <div class="input-material">
                            <input id="number" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.number" required/>
                            <label class="disabled" for="number">Nº</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="complement" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.complement" required/>
                            <label class="disabled" for="complement">Complemento</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="state" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.city.uf.name" required/>
                            <label class="disabled" for="state">Estado</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="city" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.city.name" required/>
                            <label class="disabled" for="city">Cidade</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="district" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [readOnly]="true"
                                   [disabled]="true"
                                   [(ngModel)]="model.district" required/>
                            <label class="disabled" for="district">Bairro</label>
                        </div>
                    </div>
                    <div class="row flex-column ml-2 mt-2">
                        <div class="col-12">
                            <div class="custom-control custom-checkbox my-1 mr-sm-2">
                                <input type="checkbox" class="custom-control-input" id="customControlInline" [checked]="model.isAccept" [disabled]="true">
                                <label class="custom-control-label" for="customControlInline">Li e Aceito os Termos do Regulamento</label>
                            </div>
                            <div class="custom-control custom-checkbox my-1 mr-sm-2">
                                <input type="checkbox" class="custom-control-input" id="customControlInline2" [checked]="model.isReceiveInfo" [disabled]="true">
                                <label class="custom-control-label" for="customControlInline2">Aceito Receber informações sobre a Campanha</label>
                            </div>
                            <div class="custom-control custom-checkbox my-1 mr-sm-2">
                                <input type="checkbox" class="custom-control-input" id="customControlInline3" [checked]="model.isAcceptLgpd" [disabled]="true">
                                <label class="custom-control-label" for="customControlInline2">Aceito os termos LGPD</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-header">
                <h2 class="title">Meus Cupons</h2>
            </div>
            <div class="card-body">
                <div class="row">
                    <!--<div class="col-12 py-5 text-center" *ngIf="model.coupons.length === 0">
                        Nenhum registro encontrado.
                    </div>-->
                    <div class="col-12">
                        <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th class="align-middle w-10" scope="col">Opções</th>
                                <th class="align-middle w-15" scope="col">Data Cadastro</th>
                                <th class="align-middle w-10 text-right" scope="col">Quantidade</th>
                                <th class="align-middle w-10 text-right" scope="col">Total Produtos</th>
                                <th class="align-middle w-10 text-right" scope="col">Total Serviços</th>
                                <th class="align-middle w-10 text-right" scope="col">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngIf="model.coupons.length === 0">
                                <td colspan="8" class="text-center py-5">
                                    Nenhum registro encontrado.
                                </td>
                            </tr>
                            <tr  *ngFor="let r of model.coupons">
                                <td class="text-center">
                                    <a href="javascript:void('');" class="btn btn-sm btn-outline-info" (click)="onDetailNf(r.couponNfs)">
                                        Detalhe
                                    </a>
                                </td>
                                <td>{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-right">{{r.qty}}</td>
                                <td class="text-right">{{r.totalProduct | currency: 'BRL'}}</td>
                                <td class="text-right">{{r.totalService | currency: 'BRL'}}</td>
                                <td class="text-right">{{r.total | currency: 'BRL'}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <!--<div class="col-xl-4 mb-3" *ngFor="let x of model.coupons">
                        <div class="card">
                            <div class="text-center card-body">
                                <div>
                                    <div class="text-left">
                                        <p class="text-muted d-flex justify-content-between align-items-end border-bottom pb-2">
                                            <strong>Número do cupom: </strong> <span class="ms-2 h4 mb-0">{{x.number}}</span>
                                        </p>
                                        <p class="text-muted"><strong>Tipo: </strong> <span class="ms-2">{{x.type.typeName}}</span></p>
                                        <p class="text-muted"><strong>CPF: </strong> <span class="ms-2">{{x.cpf}}</span></p>
                                        <p class="text-muted"><strong>Data da compra: </strong> <span class="ms-2">{{x.createdAt | date: 'dd/MM/yyyy'}}</span></p>
                                        <p class="text-muted"><strong>CNPJ da Nota: </strong> <span class="ms-2">{{x.dealership?.cnpj}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-header">
                <h2 class="title">Números da Sorte</h2>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 py-5 text-center" *ngIf="model.pincode.length === 0">
                        Nenhum registro encontrado.
                    </div>
                    <div class="col-xl-4 mb-3 col-lg-12" *ngFor="let x of model.pincode">
                        <div class="card">
                            <div class="card-body widget-user">
                                <div class="text-center">
                                    <h3 class="fw-normal text-primary">{{x.luckyNumber.serie}} / {{x.luckyNumber.number}}</h3>
                                    <h6>Criado em {{x.luckyNumber.createdAt | date: 'dd/MM/yyyy'}}</h6>
                                    <h5 class="mt-4 fw-normal text-secondary text-uppercase">{{x.pincode}}<br/><small>Pincode</small></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>


<div class="modal fade" id="modalDetailNf" tabindex="-1" role="dialog" aria-labelledby="activitiesModal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Notas Fiscais Cupom</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body px-5">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-3" *ngFor="let x of modelDetail">
                        <div class="card">
                            <div class="text-center card-body">
                                <div>
                                    <div class="text-left">
                                        <p class="text-muted d-flex justify-content-between align-items-end border-bottom pb-2">
                                            <strong>Número do cupom: </strong> <span class="ms-2 h4 mb-0">{{x.number}}</span>
                                        </p>
                                        <p class="text-muted"><strong>Tipo: </strong> <span class="ms-2">{{x.type.typeName}}</span></p>
                                        <p class="text-muted"><strong>CPF: </strong> <span class="ms-2">{{x.cpf}}</span></p>
                                        <p class="text-muted"><strong>Data da compra: </strong> <span class="ms-2">{{x.buyedAt | date: 'dd/MM/yyyy'}}</span></p>
                                        <p class="text-muted"><strong>CNPJ da Nota: </strong> <span class="ms-2">{{x.dealership?.cnpj}}</span></p>
                                        <td class="text-muted text-right"><strong>Total: {{x.total | currency: 'BRL'}}</strong> <span class="ms-2"> </span></td>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row col-12 d-flex justify-content-center">
                    <div class="col-md-4 my-2 col-12 my-2">
                        <button type="button" class="btn btn-block btn-outline-primary " data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
