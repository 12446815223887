export class ContactResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Contact[];
    constructor() {
        this.results = [];
    }
}

export class Contact {
    id: string;
    userId: string;
    dealerId: string;
    name: string;
    dn: string;
    fantasy: string;
    answer: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
    createdAt: Date;
    status: ContactStatus;
    constructor() {
        this.status = new ContactStatus();
    }
}

export class ContactStatus {
    id: string;
    status: string;
    constructor() {
    }
}
