<section class="container">

    <div class="d-flex align-items-center justify-content-between ml-1 mt-4">
        <div class="d-flex">
            <svg-icon class="svg" src="assets/svg/dashboard.svg"></svg-icon>
            <h1 class="title ml-4">Dashboard</h1>
        </div>
    </div>

    <div class="mt-3">

        <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card bg-white">
                    <div class="card-header d-flex align-items-center">
                        <i class="badge badge-pill badge-secondary" data-toggle="popover" title="CPF's Cadastrados" data-content="Exibição do número de usuários que realizaram o cadastro na promoção.">?</i>
                        <h2 class="subTitle mb-0 ml-2">CPF's</h2>
                    </div>
                    <div class="card-body p-0">
                        <div class="list-primary">
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Hoje</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCpf.today)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Semana</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCpf.week)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCpf.total)}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card bg-white">
                    <div class="card-header d-flex align-items-center">
                        <i class="badge badge-pill badge-secondary" data-toggle="popover" title="CPF's com Cupons" data-content="Exibição do número de usuários que possuem cupons cadastrados.">?</i>
                        <h2 class="subTitle mb-0 ml-2">CPF's com cupons</h2>
                    </div>
                    <div class="card-body p-0">
                        <div class="list-primary">
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Hoje</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCpfCoupon.today)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Semana</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCpfCoupon.week)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCpfCoupon.total)}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card bg-white">
                    <div class="card-header d-flex align-items-center">
                        <i class="badge badge-pill badge-secondary" data-toggle="popover" title="Total de Cupons" data-content="Exibição do número de cupons cadastrados desde o ínicio da promoção até o momento.">?</i>
                        <h2 class="subTitle mb-0 ml-2">Total de cupons</h2>
                    </div>
                    <div class="card-body p-0">
                        <div class="list-primary">
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Hoje</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCouponTotal.today)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Semana</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCouponTotal.week)}}</h2>
                            </div>
                            <div class="list-primary-item">
                                <span class="list-primary-item-lbl">Total</span>
                                <h2 class="list-primary-item-number text-primary" data-plugin="counterup">{{getFormatNumber(modelDashCouponTotal.total)}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row my-4">
            <div class="col-12 col-lg-6">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div class="card">
                            <div class="card-header d-flex align-items-center">
                                <h2 class="subTitle mb-0 ml-2">Números da sorte gerados</h2>
                            </div>
                            <div class="card-body widget-user">
                                <div class="text-center">
                                    <h3 class="fw-normal text-primary" data-plugin="counterup">{{getFormatNumber(modelDashLuckyNumberTotal.total)}}</h3>
                                    <h5>Total</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div class="card">
                            <div class="card-header d-flex align-items-center">
                                <h2 class="subTitle mb-0 ml-2">Concessionaria sem cupom</h2>
                            </div>
                            <div class="card-body widget-user">
                                <div class="text-center">
                                    <h3 class="fw-normal text-primary" data-plugin="counterup">{{getFormatNumber(modelDashDealerCoupon.no)}}</h3>
                                    <h5>Total</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Fale conosco</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="row">
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                                <div class="text-center">
                                    <h3 class="fw-normal text-success" data-plugin="counterup">{{!modelDashContact.answered ? 0 : getFormatNumber(modelDashContact.answered)}}</h3>
                                    <h5 class="text-success">Respondidos</h5>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                                <div class="text-center">
                                    <h3 class="fw-normal text-danger" data-plugin="counterup">{{!modelDashContact.notAnswered ? 0 : getFormatNumber(modelDashContact.notAnswered)}}</h3>
                                    <h5 class="text-danger">Não respondidos</h5>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                                <div class="text-center">
                                    <h3 class="fw-normal text-primary" data-plugin="counterup">{{!modelDashContact.total ? 0 : getFormatNumber(modelDashContact.total)}}</h3>
                                    <h5>Total</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row my-4">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <div class="card bg-white">
                    <div class="card-header">
                        <h2 class="subTitle">Tipos de NFs cadastrados</h2>
                    </div>
                    <div class="card-body js-chart-box">
                        <p class="py-5 text-center" style="min-height: 230px" *ngIf="emptyChartTypes">Nenhum resultado encontrado.</p>
                        <google-chart *ngIf="!emptyChartTypes"
                                [type]="chartTypePie"
                                [data]="chartPinCodesRegister"
                                [width]="chartWidth"
                                [height]="height">
                        </google-chart>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card bg-white">
                    <div class="card-header">
                        <h2 class="subTitle">Pincodes cadastrados por região</h2>
                    </div>
                    <div class="card-body js-chart-box">
                        <p class="py-5 text-center" style="min-height: 230px" *ngIf="emptyChartRegion">Nenhum resultado encontrado.</p>
                        <google-chart *ngIf="!emptyChartRegion"
                                [type]="chartTypePie"
                                [data]="chartPinCodesRegion"
                                [width]="chartWidth"
                                [height]="height">
                        </google-chart>
                    </div>
                </div>
            </div>
        </div>

        <div class="row my-4">
            <div class="col-12">
                <div class="card bg-white">
                    <div class="card-header">
                        <h2 class="subTitle">Usuários & Cupons</h2>
                    </div>
                    <div class="card-body js-chart-box-full">
                        <google-chart
                                [type]="chartAccessUsers.type"
                                [data]="chartAccessUsers.data"
                                [columns]="chartAccessUsers.columnNames"
                                [options]="chartAccessUsers.options"
                                [width]="chartWidthFull"
                                [height]="chartAccessUsers.height">
                        </google-chart>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="row my-4">
            <div class="col-12">
                <div class="card bg-white">
                    <div class="card-header">
                        <h2 class="subTitle">Cupons cadastrados</h2>
                    </div>
                    <div class="card-body">
                        <p style="display: flex; height: 250px; align-items: center; justify-content: center;">line chart</p>
                    </div>
                </div>
            </div>
        </div>-->

    </div>

</section>
