export class UserAdmin {
    id: string;
    name: string;
    email: string;
    profileId: string;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;
    status: boolean;
    password: string;
    passwordConfirm: string;

    constructor() {
        this.status = true;
    }
}


export class UserAdminResult {
    results: UserAdmin[];
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;

    constructor() {
        this.results = [];
    }
}
