import { Component, OnInit } from '@angular/core';
import {Client, ClientResult} from "../../../model/clients/clients";
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactService} from "../../../service/contact.service";
import {ClientService} from "../../../service/client.service";
import {CouponNf} from "../../../model/coupon/coupon";

declare var $: any;

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends BaseComponent implements OnInit {

  paramId = null;
  model: Client = new Client();
  modelDetail: CouponNf[] = [];

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public clientService: ClientService) {
    super(router);
  }

  ngOnInit(): void {
    this.route2.params.subscribe(dataRouterParams => {
      // console.log('dataRouterParams');
      // console.log(dataRouterParams);
      if (!this.isNullOrUndefined(dataRouterParams.id)) {
        this.paramId = dataRouterParams.id;
        this.getClient();
      }
      // this.initGallery();
    });
  }

  getClient() {
    this.clientService.getClientDetail(this.paramId).subscribe({
      next: data => {
        this.model = data as Client;
      }
    });
  }

  onDetailNf(a: CouponNf[]) {
    this.modelDetail = a as CouponNf[];
    $('#modalDetailNf').modal('show');
  }

}
