import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {ContactFilter} from "../../model/contact/contactFilter";
import {Contact, ContactResult} from "../../model/contact/contact";
import {ContactService} from "../../service/contact.service";
import {User} from "../../model/user/user";
import {environment} from "../../../environments/environment";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent implements OnInit, AfterViewInit {

    currentPage = 1;
    modelFilter: ContactFilter = new ContactFilter();
    modelResult: ContactResult = new ContactResult();
    modelDetail: Contact = new Contact();

    arrStatus = [
        {
            id: 'e9be87ef-41e9-4ad5-9ee4-4f74a0e9dcaa',
            status: 'Não Respondido'
        },
        {
            id: '59c1df58-9cf5-4988-8ab1-3ad6ca366aee',
            status: 'Em Análise'
        },
        {
            id: '442f0a6b-4b99-4b2a-8f8e-f29bd5ac0d36',
            status: 'Respondido'
        }
    ];
    currentStatus: any = {};

    constructor(public router: Router,
                public contactService: ContactService) {
        super(router);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.onSearch();
    }

    ngAfterViewInit(): void {
        $('#modalContactDetail').on('hidden.bs.modal', () => {
            this.modelDetail = new Contact();
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.onSearch();
    }

    setDatePicker() {
        this.modelFilter.start = moment().subtract(15, 'days');
        this.modelFilter.end = moment();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: moment().subtract(15, 'days'),
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.format();
            this.modelFilter.end = end.format();
        });
    }

    onSearch() {
        this.contactService.getContactList(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ContactResult;
            }
        });
    }

    onUpdate(a: Contact) {
        this.modelDetail = Object.assign({}, a);
        this.currentStatus = this.modelDetail.status;
        $('#modalContactDetail').modal('show');
    }

    onUpdateStatus() {
        this.confirmMessage('Atenção', 'Deseja realmente alterar o status dessa mensagem?', () => {
            this.setUpdateStatus(this.modelDetail.id, true);
        });
    }

    setUpdateStatus(id: string, msgCallBack: boolean) {
        this.contactService.updateContactStatus(this.modelDetail.id).subscribe({
            next: data => {
                if (msgCallBack) {
                    $('#modalContactDetail').modal('hide');
                    this.showMessage('Sucesso!', 'Status alterado com sucesso!', 'success');
                }
                this.currentPage = 1;
                this.onSearch();
            }, error: err => super.onError(err)
        });
    }

    onSave() {
        const arrValidateFields = [
            {value: this.modelDetail.answer, text: 'Resposta*, <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`, 'warning');
            return;
        }
        const a: any = {message: this.modelDetail.answer, status: this.currentStatus.id};
        this.contactService.updateContactSendResponse(this.modelDetail.id, a).subscribe({
            next: data => {
                $('#modalContactDetail').modal('hide');
                this.modelResult = new ContactResult();
                this.currentPage = 1;
                this.onSearch();
                this.showMessage('Sucesso!', 'Resposta enviada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/contact/search/excel?token=${token}`;
        if (!super.isNullOrUndefined(this.modelFilter.start)) {
            const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(this.modelFilter.end)) {
            const end = moment(this.modelFilter.end).format('YYYY-MM-DD');
            url += ('&end=' + end);
        }
        if (!super.isNullOrUndefined(this.modelFilter.email)) {
            url += ('&email=' + this.modelFilter.email);
        }
        if (!super.isNullOrUndefined(this.modelFilter.status)) {
            url += ('&status=' + this.modelFilter.status);
        }
        console.log(encodeURI(url));
        window.open(encodeURI(url));
    }

    getStatusById(id: string) {
        return this.arrStatus.filter(x => x.id === id)[0];
    }

    onChangeStatus(o: any) {
        this.currentStatus = o;
    }
}
