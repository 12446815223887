import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {Router} from "@angular/router";
import {DashEnum} from "../../../model/common";
import {DashboardService} from "../../../service/dashboard.service";

declare var $: any;

@Component({
  selector: 'app-dash-home',
  templateUrl: './dash-home.component.html',
  styleUrls: ['./dash-home.component.scss']
})
export class DashHomeComponent extends BaseComponent implements OnInit {

  modelDashCpf: DashEnum = new DashEnum();
  modelDashCpfCoupon: DashEnum = new DashEnum();
  modelDashCouponTotal: DashEnum = new DashEnum();
  modelDashLuckyNumberTotal: DashEnum = new DashEnum();
  modelDashDealerCoupon: DashEnum = new DashEnum();
  modelDashContact: DashEnum = new DashEnum();
  emptyChartTypes = true;
  emptyChartRegion = true;

  chartTypePie = 'PieChart';
  height = 250;

  chartPinCodesRegister = [
    ['Peças', 0],
    ['Serviços', 0],
    ['Peças & Serviços', 0]
  ];
  chartPinCodesRegion: any = [
    ['', 0],
    ['', 0],
    ['', 0],
    ['', 0],
    ['', 0]
  ];

  chartAccessUsers = {
    type: 'LineChart',
    data: [
      ["01/01/2021",  0, 0],
    ],
    columnNames: ["Data", "Cupons", "Usuários"],
    options: {
    },
    width: '100%',
    height: 250
  };

  constructor(public router: Router,
              public dashService: DashboardService) {
    super(router);
  }

  ngOnInit(): void {
    $(function () {
      $('[data-toggle="popover"]').popover({
        trigger: 'hover'
      })
    });
    super.resizeCharts();
    this.getChartRegion();
    this.getDashCounters();
    this.getChartLines();
    this.getChartTypes();
  }

  getDashCounters() {
    const arr = ['cpf', 'cpf-with-coupon',  'total-coupon', 'lucky-number-count', 'dealer-coupon', 'contact'];
    arr.map((x, i) => {
      this.dashService.getCounters(x).subscribe({
        next: data => {
          const a: any = data;
          switch (x) {
            case 'cpf':
              this.modelDashCpf = a;
              break;
            case 'cpf-with-coupon':
              this.modelDashCpfCoupon = a;
              break;
            case 'total-coupon':
              this.modelDashCouponTotal = a;
              break;
            case 'lucky-number-count':
              this.modelDashLuckyNumberTotal = a;
              break;
            case 'dealer-coupon':
              this.modelDashDealerCoupon = a;
              break;
            case 'contact':
              this.modelDashContact = a;
              break;
          }

        }
      });
    })
  }

  getChartRegion() {
    this.dashService.getPincodeRegion().subscribe({
      next: data => {
        const x: any = data;
        this.chartPinCodesRegion = x.data;
        if (this.isNullOrUndefined(x.data)) {
          this.emptyChartRegion = true;
          this.chartPinCodesRegister = [
            ['', 0],
            ['', 0],
            ['', 0],
            ['', 0],
            ['', 0]
          ];
        } else {
          const a = this.chartPinCodesRegion[0];
          const b = this.chartPinCodesRegion[1];
          const c = this.chartPinCodesRegion[2];
          const d = this.chartPinCodesRegion[3];
          const e = this.chartPinCodesRegion[4];
          if (a[1] === 0 && b[1] === 0 && c[1] === 0 && d[1] === 0 && d[1] === 0) {
            this.emptyChartRegion = true;
          } else {
            this.emptyChartRegion = false;
          }
          this.chartPinCodesRegion = x.data;
        }
      }
    });
  }


  getChartTypes() {
    this.dashService.getPincodeType().subscribe({
      next: data => {
        const a: any = data;
        if (this.isNullOrUndefined(a.data)) {
          this.emptyChartTypes = true;
          this.chartPinCodesRegister = [
            ['Peças', 0],
            ['Serviços', 0],
            ['Peças & Serviços', 0]
          ];
        } else {
          this.emptyChartTypes = false;
          this.chartPinCodesRegister = a.data;
        }
      }
    });
  }

  getChartLines() {
    this.dashService.getUserCouponLines(10).subscribe({
      next: data => {
        const a: any = data;
        this.chartAccessUsers.columnNames = a.columns;
        this.chartAccessUsers.data = a.data;
      }
    });
  }

}
