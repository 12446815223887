<div class="card mt-3 bg-white">
    <div class="card-header">
        <div class="row">
            <div class="col-12 col-lg-8 mb-3 mb-lg-0">
                <h2 class="title">Analytics</h2>
            </div>
            <div class="col-12 col-lg-4">
                <div class="input-material mb-0">
                    <input id="date" class="form-control js-daterangepicker" type="text" maxlength="200"
                           autocomplete="off" required/>
                    <label for="date">Periodo</label>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-xl-3 col-lg-12">
                <div class="card">
                    <div class="card-body widget-user">
                        <div class="row justify-content-center">
                            <span class="ico"><svg-icon [svgClass]="'svg-analytics'"
                                                        src="assets/svg/mobile.svg"></svg-icon></span>
                            <div class="text-center">
                                <h2 class="fw-normal text-primary">500.000</h2>
                                <h5>Mobile</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-12">
                <div class="card">
                    <div class="card-body widget-user">
                        <div class="row justify-content-center">
                            <span class="ico svg-analytics"><svg-icon [svgClass]="'svg-analytics'"
                                                                      src="assets/svg/desktop.svg"></svg-icon></span>
                            <div class="text-center">
                                <h2 class="fw-normal text-primary">500.000</h2>
                                <h5>Desktop</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-12">
                <div class="card">
                    <div class="card-body widget-user">
                        <div class="row justify-content-center">
                            <span class="ico svg-analytics"><svg-icon [svgClass]="'svg-analytics'"
                                                                      src="assets/svg/tablet.svg"></svg-icon></span>
                            <div class="text-center">
                                <h2 class="fw-normal text-primary">500.000</h2>
                                <h5>Tablet</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-12">
                <div class="card">
                    <div class="card-body widget-user">
                        <div class="row justify-content-center">
                            <span class="ico svg-analytics"><svg-icon [svgClass]="'svg-analytics'"
                                                                      src="assets/svg/qr-code.svg"></svg-icon></span>
                            <div class="text-center">
                                <h2 class="fw-normal text-primary">500.000</h2>
                                <h5>QR Code</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row my-4">
    <div class="col-12 col-lg-12">
        <div class="row">
            <div class="col-md-12 col-lg-3 col-xl-3 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Visitas</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">0</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Visitantes únicos</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">0</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Visualizações de página</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">0</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Páginas/visita</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">0</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row my-4">
    <div class="col-12 col-lg-12">
        <div class="row">
            <div class="col-md-12 col-lg-4 col-xl-4 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Duração média da visita</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Taxa de rejeição</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">0 %</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Porcentagem de novas visitas</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">0 %</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card mt-3 bg-white">
    <div class="card-header">
        <h5 class="font-weight-bold">Duração por página</h5>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 col-lg-4 col-xl-4 mb-3">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Dashboard</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 mb-4">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Consultar Pincode</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 mb-3">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Relatórios</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Estatísticas</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Analytics</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3 mb-3 mb-lg-0">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Fale Conosco</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-xl-3 mb-1">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h2 class="subTitle mb-0 ml-2">Administradores</h2>
                    </div>
                    <div class="card-body widget-user">
                        <div class="text-center">
                            <h3 class="fw-normal text-primary" data-plugin="counterup">00:00:00</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


