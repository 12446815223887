import {Component, OnDestroy, OnInit} from '@angular/core';
import {City, ClientResult, Uf} from "../../model/clients/clients";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {ClientService} from "../../service/client.service";
import {CouponService} from "../../service/coupon.service";
import {CouponNf, CouponResult} from "../../model/coupon/coupon";
import {CouponFilter} from "../../model/coupon/couponFilter";
import {environment} from "../../../environments/environment";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-numbers',
    templateUrl: './numbers.component.html',
    styleUrls: ['./numbers.component.scss']
})
export class NumbersComponent extends BaseComponent implements OnInit, OnDestroy {

    currentPage = 1;
    modelFilter: CouponFilter = new CouponFilter();
    modelResult: CouponResult = new CouponResult();
    modelDetail: CouponNf[] = [];

    selectedState = null;
    selectedCity = null;
    listState: Uf[] = [];
    listCity: City[] = [];

    constructor(public router: Router,
                public couponService: CouponService,
                public clientService: ClientService) {
        super(router);
    }

    ngOnInit(): void {
        this.onState();
        this.setDatePicker();
        this.onSearch();
    }

    ngOnDestroy(): void {
        const a = $('#modalDetailNf');
        if (a[0]) {
            a.remove();
        }
    }

    pageChanged(event) {
        this.currentPage = event;
        this.onSearch();
    }

    setDatePicker() {
        this.modelFilter.begin = moment().subtract(15, 'days').format();
        this.modelFilter.end = moment().format();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: moment().subtract(15, 'days'),
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.begin = start.format();
            this.modelFilter.end = end.format();
        });
    }

    onSearch() {
        this.modelFilter.page = this.currentPage;
        this.couponService.getCoupons(this.modelFilter).subscribe({
            next: data => {
                this.modelResult = data as CouponResult;
            }
        });
    }

    onState() {
        this.clientService.getState().subscribe({
            next: data => {
                this.listState = data as Uf[];
            }
            , error: err => super.onError(err)
        });
    }

    onCity(i, select?) {
        if (this.isNullOrUndefined(i)) {
            this.listCity = [];
            this.modelFilter.stateId = undefined;
            this.modelFilter.cityId = undefined;
        } else {
            this.modelFilter.stateId = i;
            this.clientService.getCity(i).subscribe({
                next: data => {
                    this.listCity = data as City[];
                }, error: err => super.onError(err)
            });
        }
    }

    onCitySelect(i) {
        this.selectedCity = i;
        this.modelFilter.cityId = i;
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/report/coupons-excel?token=${token}`;
        if (!super.isNullOrUndefined(this.modelFilter.begin)) {
            const start = moment(this.modelFilter.begin).format('YYYY-MM-DD');
            url += ('&begin=' + start);
        }
        if (!super.isNullOrUndefined(this.modelFilter.end)) {
            const end = moment(this.modelFilter.end).format('YYYY-MM-DD');
            url += ('&end=' + end);
        }
        if (!super.isNullOrUndefined(this.modelFilter.number)) {
            url += ('&number=' + this.modelFilter.number);
        }
        if (!super.isNullOrUndefined(this.modelFilter.cityId)) {
            url += ('&cityId=' + this.modelFilter.cityId);
        }
        if (!super.isNullOrUndefined(this.modelFilter.stateId)) {
            url += ('&stateId=' + this.modelFilter.stateId);
        }
        window.open(encodeURI(url));
    }

    onDetailNf(a: CouponNf[]) {
        this.modelDetail = a as CouponNf[];
        $('#modalDetailNf').modal('show');
    }
}
