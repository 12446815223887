import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from "../../service/user.service";
import {User} from "../../model/user/user";

declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

  model: User = new User();

  constructor(public router: Router,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    moment.locale('pt-br');
    setTimeout(() => {
      $('#loaderBox').fadeOut();
    }, 1000);
    console.log('main')
    console.log(this.userService.checkAuthenticated());
    this.userService.checkAuthenticated();
    if (this.userService.isAuthenticated) {
      this.userService.getMe().subscribe(
          data => {
            const a = data as User;
            this.userService.updateUserDataSource(a);
            super.toggleLoader(false);
          },
          error => {
            this.onError(error);
            localStorage.clear();
            this.userService.isAuthenticated = false;
            super.toggleLoader(false);
            this.router.navigate(['/login']);
          }
      );
    } else {
      console.log('nao ta logado');
      super.toggleLoader(false);
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewInit(): void {
    $(document).on('show.bs.modal', '.modal', function() {
      $(this).appendTo('body');
    });
  }

}
