import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {User} from "../model/user/user";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {
  isAuthenticated = false;

  private userDataSource = new BehaviorSubject<User>(new User());
  user = this.userDataSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  updateUserDataSource(user) {
    this.userDataSource.next(user);
  }

  checkAuthenticated() {
    const token = localStorage.getItem('token') as string;
    if (token != null) {
      this.isAuthenticated = true;
    }
    return this.isAuthenticated;
  }

  getAuthenticate(model) {
    const url = `${this.BASE_URL}/admin/authenticate`;
    return this.http.post(url, model);
  }

  getMe() {
    const url = `${this.BASE_URL}/admin`;
    return this.http.get(url);
  }

  forgot(model) {
    const url = `${this.BASE_URL}/user/forgot`;
    return this.http.post(url, model);
  }

  reset(data) {
    const url = `${this.BASE_URL}/user/reset-password`;
    return this.http.post(url, data);
  }

  validate(id) {
    const url = `${this.BASE_URL}/user/validate-reset-token/${id}`;
    return this.http.get(url);
  }

  getPincode(p) {
    const url = `${this.BASE_URL}/pincode/verify/${p}`;
    return this.http.get(url);
  }

  getAdminUser(m) {
    const url = `${this.BASE_URL}/admin/search`;
    return this.http.post(url, m);
  }

  setAdminUserCreate(m) {
    const url = `${this.BASE_URL}/admin/create`;
    return this.http.post(url, m);
  }

  deleteAdminUser(id) {
    const url = `${this.BASE_URL}/admin/${id}`;
    return this.http.delete(url);
  }


}
