import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class CouponService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getCoupons(m: any) {
        const url = `${this.BASE_URL}/report/coupons`;
        return this.http.post(url, m);
    }
}
