import {Coupon, LuckyNumber, Pincode} from "../coupon/coupon";

export class ClientResult {
    results: Client[];
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;

    constructor() {
        this.results = [];
    }
}

export class Client {
    id: string;
    cpf: string;
    name: string;
    birthday: Date;
    email: string;
    phone: string;
    sex: string;
    salt: string;
    cellPhone: string;
    address: string;
    number: string;
    district: string;
    complement: string;
    zipcode: string;
    createdAt: Date;
    updatedAt: Date;
    isAccept: boolean;
    isAcceptLgpd: boolean;
    isReceiveInfo: boolean;
    city: City;
    coupons: Coupon[];
    luckyNumber: LuckyNumber[];
    pincode: Pincode[];

    constructor() {
        this.coupons = [];
        this.luckyNumber = [];
        this.pincode = [];
        this.city = new City();
    }
}


export class City {
    id: string;
    name: string;
    uf: Uf;

    constructor() {
        this.uf = new Uf();
    }
}

export class Uf {
    id: string;
    name: string;
    region: string;

    constructor() {
    }
}

