import {City} from "../clients/clients";

export class DealershipResult {
    results: Dealership[];
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;

    constructor() {
        this.results = [];
    }
}

export class Dealership {
    id: string;
    dn: number;
    region: number;
    companyName: string;
    fantasyName: string;
    hasToken: string;
    cnpj: string;
    stateId: string;
    city: string;
    vwbs: number;
    vwbsDistributed: number;
    vwgl: number;
    vwglDistributed: number;
    vwfs: number;
    vwfsDistributed: number;
    vwkb: number;
    vwkbDistributed: number;
    total: number;
    totalDistributed: number;
    name: string;
    email: string;
    phone: string;
    isValidated: boolean;
    summary: Summary;

    constructor() {
        this.summary = new Summary();
    }
}

export class Summary {
    vwbs: number;
    vwgl: number;
    vwfs: number;
    vwkb: number;
    total: number;
    distributedAt: Date;

    constructor() {
    }
}
