
export class DashEnum {

    today: number;
    week: number;
    total: number;
    answered: number;
    notAnswered: number;
    yes: number;
    no: number;

    constructor() {
        this.today = 0;
        this.week = 0;
        this.total = 0;
    }
}
