export class Analytics {
    idProject: string;
    beginDate: string;
    endDate: string;
    metrics: string;
    dimensions: string;
    order: string;
    filters: string;

    constructor() {
    }
}
