export class ClientFilter {
    begin: Date;
    end: Date;
    name: string;
    cpf: string;
    cityId: string;
    stateId: string;
    page: number;

    constructor() {
    }
}
