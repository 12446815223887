<section class="container">

    <div class="d-flex align-items-center ml-1 mt-4">
        <svg-icon class="svg" src="assets/svg/contact.svg"></svg-icon>
        <h1 class="title ml-4">Fale Conosco</h1>
    </div>

    <div class="mt-3">

        <!--<div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>-->

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="200"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.name"
                                   required/>
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <input id="document" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.email"
                                   required
                            />
                            <label for="document">E-mail</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <select id="status" class="form-control" name="type"
                                    [(ngModel)]="modelFilter.status">
                                <option selected [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let x of arrStatus" [ngValue]="x.status">{{x.status}}</option>
                            </select>
                            <label for="status" class="mb-0 mr-3">Status</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="currentPage = 1; onSearch();"
                        >
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="align-middle w-10" scope="col">Data/Hora</th>
                        <th class="align-middle w-5" scope="col">DN</th>
                        <th class="align-middle w-15" scope="col">Fantasia</th>
                        <th class="align-middle w-15" scope="col">Nome</th>
                        <th class="align-middle" scope="col">E-mail</th>
                        <th class="align-middle w-15" scope="col">Telefone</th>
                        <th class="align-middle" scope="col">Assunto</th>
                        <th class="align-middle w-30" scope="col">Mensagem</th>
                        <th class="align-middle" scope="col">Origem</th>
                        <th class="align-middle" scope="col">Status</th>
                        <th class="align-middle text-center" scope="col">Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="modelResult.results.length === 0">
                        <td colspan="8" class="text-center py-5">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
                        <td>{{r.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>{{r.dn}}</td>
                        <td>{{r.fantasy}}</td>
                        <td>{{r.name}}</td>
                        <td>{{r.email}}</td>
                        <td>{{r.phone}}</td>
                        <td>{{r.subject}}</td>
                        <td>{{r.message}}</td>
                        <td>{{isNullOrUndefined(r.userId) ? 'Concessionário' : 'Cliente'}}</td>
                        <td>
                            <span class="badge"
                                  [ngClass]="{
                                    'badge-success' : r.status.id === arrStatus[2].id,
                                    'badge-warning' : r.status.id === arrStatus[1].id,
                                    'badge-danger' : r.status.id === arrStatus[0].id
                                  }">{{r.status.status}}</span>
                        </td>
                        <td class="text-center">
                            <a href="javascript:void('');" class="btn btn-sm btn-outline-info" (click)="onUpdate(r)">
                                Detalhe
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>


<div class="modal-custom modal" id="modalContactDetail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalContactDetail"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>FALE CONOSCO</h3>
                <a href="javascript:void('Fechar');" class="close text-white" data-dismiss="modal"
                   aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row mt-1 text-right">
                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <div *ngIf="modelDetail.status.id === arrStatus[2].id" class="badge badge-success py-1 size-md"
                             [ngClass]="{
                                    'badge-success' : modelDetail.status?.id === arrStatus[2].id,
                                    'badge-warning' : modelDetail.status?.id === arrStatus[1].id,
                                    'badge-danger' : modelDetail.status?.id === arrStatus[0].id
                                  }">{{modelDetail.status?.status}}</div>
                        <div class="input-group mb-3 justify-content-end"
                             *ngIf="modelDetail.status?.id ===  arrStatus[0].id
                                    || modelDetail.status?.id === arrStatus[1].id">
                            <div class="input-group-prepend">
                                <button class="dropdown-toggle" type="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                    <div class="badge badge-success py-1 size-md" [ngClass]="{
                                    'badge-success' : modelDetail.status?.id === arrStatus[2].id,
                                    'badge-warning' : currentStatus?.id === arrStatus[1].id,
                                    'badge-danger' : currentStatus?.id === arrStatus[0].id
                                  }">{{isNullOrUndefined(currentStatus) ? modelDetail.status?.status : currentStatus.status}}</div>
                                </button>
                                <div class="dropdown-menu"
                                     *ngIf="modelDetail.status?.id === arrStatus[0].id
                                            || modelDetail.status?.id === arrStatus[1].id">
                                    <a class="dropdown-item" href="javascript:void('');"
                                       *ngIf="modelDetail.status?.id !== arrStatus[1].id" (click)="onChangeStatus(arrStatus[0])">Não Respondido</a>
                                    <a class="dropdown-item" href="javascript:void('');"
                                       *ngIf="modelDetail.status?.id !== arrStatus[1].id" (click)="onChangeStatus(arrStatus[1])">Em análise</a>
                                    <a class="dropdown-item" href="javascript:void('');" (click)="onUpdateStatus()">Respondido</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12 col-lg-5 offset-lg-1">
                        <p class="text-muted size-md"><strong>Nome:</strong><span
                                class="ml-2">{{modelDetail.name}}</span></p>
                        <p class="text-muted size-md"><strong>Email:</strong> <span
                                class="ml-2">{{modelDetail.email}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-6">
                        <p class="text-muted size-md"><strong>Data/Hora:</strong>
                            <span class="ml-2">{{modelDetail.createdAt | date: 'dd/MM/yyyy HH:mm'}}</span></p>
                        <p class="text-muted size-md"><strong>Telefone:</strong><span
                                class="ml-2">{{modelDetail.phone}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <p class="text-muted size-md"><strong>Mensagem:</strong><span
                                class="ml-2">{{modelDetail.message}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <hr/>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1 text-right">
                        <div class="text-left mb-2">
                            <p class="text-muted size-md"><strong>Resposta:</strong></p>
                        </div>
                        <textarea class=" input-material mb-0" id="description"
                                  [(ngModel)]="modelDetail.answer"
                                  [disabled]="modelDetail.status.id !== arrStatus[0].id"
                                  style="width: inherit; resize: none"
                                  rows="4" required></textarea>
                        <!--<div class="text-left mb-2">
                          Atenciosamente,<br>
                          Equipe Megaincentivo Desafio Liquigás 2021
                        </div>-->
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1 mb-4">
                        <div class="mx-auto">
                            <a href="javascript:void(0)" class="btn btn-block btn-warning mt-4 mb-5 px-5"
                               *ngIf="modelDetail.status.id === arrStatus[0].id"
                               (click)="onSave()"
                               type="button">Enviar</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
