import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class ClientService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getState() {
        const url = `${this.BASE_URL}/common/states`;
        return this.http.get(url);
    }

    getCity(p: string) {
        const url = `${this.BASE_URL}/common/cities/${p}`;
        return this.http.get(url);
    }

    getClients(m: any) {
        const url = `${this.BASE_URL}/report/users`;
        return this.http.post(url, m);
    }

    getClientDetail(id: any) {
        const url = `${this.BASE_URL}/report/users/${id}`;
        return this.http.get(url);
    }
}
