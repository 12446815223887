import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class DashboardService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getPincodeRegion() {
        const url = `${this.BASE_URL}/dashboard/pincode-by-region`;
        return this.http.get(url);
    }

    getPincodeType() {
        const url = `${this.BASE_URL}/dashboard/invoice-types-graph`;
        return this.http.get(url);
    }

    getUserCouponLines(d) {
        const url = `${this.BASE_URL}/dashboard/user-coupon-graph/${d}`;
        return this.http.get(url);
    }

    getCounters(x: string) {
        const url = `${this.BASE_URL}/dashboard/${x}`;
        return this.http.get(url);
    }
}
