import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {LoginComponent} from './views/login/login.component';
import {UserComponent} from './views/user/user.component';
import {UserDetailComponent} from './views/user/user-detail/user-detail.component';
import {AdminComponent} from './views/admin/admin.component';
import {NumbersComponent} from './views/numbers/numbers.component';
import {DashHomeComponent} from './views/dash/dash-home/dash-home.component';
import {PincodeComponent} from './views/pincode/pincode.component';
import {ContactComponent} from './views/contact/contact.component';
import {StatisticsComponent} from './views/statistics/statistics.component';
import {AnalyticsComponent} from './views/analytics/analytics.component';
import {DealershipComponent} from './views/dealership/dealership.component';
import {AuthGuard} from './service/auth-guard.service';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'logout', component: LogoutComponent
  },
  {
    path: '', component: MainComponent,
    children: [
    /*  {path: 'pincode', component: PincodeComponent},
      {path: 'usuarios', component: UserComponent},
      {path: 'usuarios-detalhe/:id', component: UserDetailComponent},
      {path: 'cupons', component: NumbersComponent},
      {path: 'concessionarias', component: DealershipComponent},
      {path: 'estatisticas', component: StatisticsComponent},
      {path: 'analytics', component: AnalyticsComponent},*/
      {path: 'fale-conosco', component: ContactComponent},
      {path: 'administradores', component: AdminComponent},
      // {path: '', component: DashHomeComponent}
      {path: '', component: ContactComponent}
    ], canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
