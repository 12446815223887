import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../base/base.component';
import {UserAdmin, UserAdminResult} from "../../model/admin/user.admin";
import {ClientFilter} from "../../model/clients/clientsFilter";
import {AdminFilter} from "../../model/admin/adminFilter";
import {ClientResult} from "../../model/clients/clients";
import {ClientService} from "../../service/client.service";
import {UserService} from "../../service/user.service";
import {User} from "../../model/user/user";

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends BaseComponent implements OnInit, OnDestroy {

  currentPage = 1;
  model: User = new User();
  modelFilter: AdminFilter = new AdminFilter();
  modelResult: UserAdminResult = new UserAdminResult();
  modelAdmin: UserAdmin = new UserAdmin();

  constructor(public router: Router,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
      }, error: err => super.onError(err)
    });
    this.onSearch();
  }

  ngOnDestroy(): void {
    const a = $('#addAdminModal');
    if (a[0]) { a.remove(); }
  }

  pageChanged(event) {
    this.currentPage = event;
    this.onSearch();
  }

  onSearch() {
    this.modelFilter.page = this.currentPage;
    this.userService.getAdminUser(this.modelFilter).subscribe({
      next: data => {
        this.modelResult = data as UserAdminResult;
      }
    });
  }

  handlerModal() {
    $('#addAdminModal').modal('show');
  }

  onSave() {
    const arrValidateFields = [
      // {value: this.modelAdmin.profileId, text: 'Perfil*, <br />'},
      // {value: this.modelAdmin.status, text: 'Status*, <br />'},
      {value: this.modelAdmin.name, text: 'Nome*, <br />'},
      {value: this.modelAdmin.email, text: 'E-mail*, <br />'},
      {value: this.modelAdmin.password, text: 'Senha*, <br />'},
      {value: this.modelAdmin.passwordConfirm, text: 'Confirmar Senha*, <br />'},
    ];
    const stringError = super.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`, 'warning');
      return;
    }
    if (!this.isEMailValid(this.modelAdmin.email)) { return; }
    if (this.modelAdmin.password !== this.modelAdmin.passwordConfirm) {
      super.showMessage('Atenção', 'As senhas não são iguais, verifique para prosseguir .', 'warning');
      return;
    }

    this.userService.setAdminUserCreate(this.modelAdmin).subscribe({
      next: data => {
        this.modelAdmin = new UserAdmin();
        $('#addAdminModal').modal('hide');
        this.showMessage('Parabéns', `Cadastro realizado com sucesso.`, 'success');
        this.currentPage = 1;
        this.onSearch();
      }, error: err => super.onError(err)
    });
  }

  onDelete(id) {
    super.confirmMessage('Atenção', 'Deseja realmente deletar este usuário administrador?', () => {
      this.userService.deleteAdminUser(id).subscribe({
        next: data => {
          this.currentPage = 1;
          this.onSearch();
          super.showMessage('Sucesso', 'Usuário deletado com sucesso!', 'success');
        }, error: err => super.onError(err)
      });
    });
  }
}
