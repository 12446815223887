import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaqComponent} from './views/help/faq/faq.component';
import {LogoutComponent} from './views/logout/logout.component';
import {LogService} from './service/log.service';
import {ContactService} from './service/contact.service';
import {DashboardService} from './service/dashboard.service';
import {ClientService} from './service/client.service';
import {DealershipService} from './service/dealership.service';
import {CouponService} from './service/coupon.service';
import {StatisticsService} from './service/statistics.service';
import {AnalyticsService} from './service/analytics.service';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import { LoginComponent } from './views/login/login.component';
import { UserComponent } from './views/user/user.component';
import { UserDetailComponent } from './views/user/user-detail/user-detail.component';
import {NgxMaskModule} from 'ngx-mask';
import { AdminComponent } from './views/admin/admin.component';
import { NumbersComponent } from './views/numbers/numbers.component';
import { DashHomeComponent } from './views/dash/dash-home/dash-home.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { PincodeComponent } from './views/pincode/pincode.component';
import { ContactComponent } from './views/contact/contact.component';
import { StatisticsComponent } from './views/statistics/statistics.component';
import { AnalyticsComponent } from './views/analytics/analytics.component';
import {HeaderHttpInterceptor} from "./base/header-interceptor";
import {EventBusService} from './service/event-bus.service';
import {registerLocaleData} from '@angular/common';
import localPt from '@angular/common/locales/pt';
import { DealershipComponent } from './views/dealership/dealership.component';

registerLocaleData(localPt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    FaqComponent,
    LogoutComponent,
    LoginComponent,
    UserComponent,
    UserDetailComponent,
    AdminComponent,
    NumbersComponent,
    DashHomeComponent,
    PincodeComponent,
    ContactComponent,
    StatisticsComponent,
    AnalyticsComponent,
    DealershipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    SlickCarouselModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GoogleChartsModule,
    NgxPaginationModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderHttpInterceptor,
        multi: true
      },
      EventBusService
    ],
    LogService,
    DashboardService,
    AppDataService,
    ContactService,
    ClientService,
    DealershipService,
    CouponService,
    StatisticsService,
    AnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

currency:'BRL'
