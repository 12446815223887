import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {StatisticsService} from "../../service/statistics.service";
import {CouponsSummary} from "../../model/stats/couponsSummary";
import {ClientFilter} from "../../model/clients/clientsFilter";
import {Analytics} from "../../model/analytics";
import {AnalyticsService} from "../../service/analytics.service";

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent extends BaseComponent implements OnInit {

  campaignId = '173712259';
  modelFilter: Analytics = new Analytics();

  constructor(public router: Router,
              public analyticsService: AnalyticsService) {
    super(router);
  }

  ngOnInit(): void {
    this.setDatePicker();
    // this.getAnalytics();
    this.setShortDevices();
  }

  setDatePicker() {
    this.modelFilter.beginDate = moment().subtract(15, 'days');
    this.modelFilter.endDate = moment();
    $('.js-daterangepicker').daterangepicker({
      showDropdowns: false,
      startDate: moment().subtract(15, 'days'),
      locale: super.objLocaleDateRangePicker()
    }, (start, end, label) => {
      this.modelFilter.beginDate = start.format();
      this.modelFilter.endDate = end.format();
    });
  }

  setShortDevices() {
    const a = {} as Analytics;
    a.idProject = this.campaignId;
    a.beginDate = moment(this.modelFilter.beginDate).format('yyyy-MM-DD');
    a.endDate = moment(this.modelFilter.endDate).format('yyyy-MM-DD');
    a.metrics = 'ga:users';
    a.dimensions = 'ga:deviceCategory';
    a.order = 'ordenacao=-ga:users';
    a.filters = undefined;
    console.log('setShortDevices');
    console.log(a);
    this.analyticsService.getAnalytics(a).subscribe({
      next: data => {
        console.log('data');
        console.log(data);
      }
    });
    // "http://analytics.produtoraduka.com.br/api/Analytics?idProjeto=ga:"+getCampanhaID()+"&dtInicial="+dataIni[2]+"-"+dataIni[1]+"-"+dataIni[0]+"&dtFinal="+dataFim[2]+"-"+dataFim[1]+"-"+dataFim[0]+"&dimensoes=ga:deviceCategory&metricas=ga:users&ordenacao=-ga:users"

  }

  getAnalytics() {
    this.analyticsService.getAnalytics(this.modelFilter).subscribe({
      next: data => {
        console.log('data');
        console.log(data);
      }
    });
  }

}
