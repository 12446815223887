import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {StatisticsService} from '../../service/statistics.service';
import {CouponsByState} from '../../model/stats/couponsByState';
import {CouponsSummary} from '../../model/stats/couponsSummary';
import {CouponsByAge} from '../../model/stats/couponsByAge';
import {CouponCityRank} from '../../model/stats/couponCityRank';
import {CouponDealershipRank} from '../../model/stats/couponDealershipRank';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent extends BaseComponent implements OnInit {

  modelCouponsByState: CouponsByState[] = [];
  modelCouponsSummary: CouponsSummary = new CouponsSummary();
  modeCouponsByAge: CouponsByAge[] = [];
  modelCouponsCityRank: CouponCityRank[] = [];
  modelCouponsDealershipRank: CouponDealershipRank[] = [];

  chartTypePie = 'PieChart';
  height = 250;

  chartPinCodesRegister = [
    ['Masculino', 0],
    ['Feminino', 0]
  ];

  chartAccessUsers = {
    type: 'LineChart',
    data: [
      ['Semana 1',  0],
      ['Semana 2',  0],
      ['Semana 3',  0]
    ],
    columnNames: ['Semana', 'Cupons'],
    options: {
      legend: {position: 'none'},
      chartArea: {
        top: 40,
        right: 40,
        bottom: 30,
        left: 40,
        width: '100%',
        height: '100%'
      },
      vAxis: {
        viewWindowMode: 'explicit',
        viewWindow: {
          min: 0
        }
      },
    },
    width: '100%',
    height: 227
  };

  constructor(public router: Router,
              public statsService: StatisticsService) {
    super(router);
  }

  ngOnInit(): void {
    this.getCouponsByState();
    this.getCouponsSumary();
    this.getCouponsAge();
    this.getCouponsGender();
    this.getCouponsByWeek();
    this.getCouponsCityRank();
    this.getCouponsDealershipRank();
    super.resizeCharts();
  }

  getCouponsByState() {
    this.statsService.getCouponsByState().subscribe({
      next: data => {
        this.modelCouponsByState = data as CouponsByState[];
      }
    });
  }

  getCouponsSumary() {
    this.statsService.getCouponsSummary().subscribe({
      next: data => {
        this.modelCouponsSummary = data as CouponsSummary;
      }
    });
  }

  getCouponsAge() {
    this.statsService.getUserByAge().subscribe({
      next: data => {
        this.modeCouponsByAge = data as CouponsByAge[];
      }
    });
  }

  getCouponsGender() {
    this.statsService.getUserByGender().subscribe({
      next: data => {
        const a: any = data;
        const b = a.data;
        this.chartPinCodesRegister =  [[b[0], b[1]], [b[2], b[3]]];
      }
    });
  }

  getCouponsByWeek() {
    this.statsService.getUserByWeek().subscribe({
      next: data => {
        const a: any = data;
        this.chartAccessUsers.data = a;
      }
    });
  }

  getCouponsCityRank() {
    this.statsService.getCityCouponRank().subscribe({
      next: data => {
        this.modelCouponsCityRank = data as CouponCityRank[];
      }
    });
  }

  getCouponsDealershipRank() {
    this.statsService.getDealershipCouponRank().subscribe({
      next: data => {
        this.modelCouponsDealershipRank = data as CouponDealershipRank[];
      }
    });
  }

}
