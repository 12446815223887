export class CouponsSummary {
    totalUsers: number;
    totalUsersWithoutCoupon: number;
    totalUsersWithCoupon: number;
    totalCoupons: number;
    couponByUserAverage: number;

    constructor() {
        this.totalUsers = 0;
        this.totalUsersWithoutCoupon = 0;
        this.totalUsersWithCoupon = 0;
        this.totalCoupons = 0;
        this.couponByUserAverage = 0;
    }
}
