import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {ContactFilter} from "../../model/contact/contactFilter";
import {ContactResult} from "../../model/contact/contact";
import {ClientFilter} from "../../model/clients/clientsFilter";
import {City, ClientResult, Uf} from "../../model/clients/clients";
import {ContactService} from "../../service/contact.service";
import {ClientService} from "../../service/client.service";
import {environment} from "../../../environments/environment";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit {

    currentPage = 1;
    modelFilter: ClientFilter = new ClientFilter();
    modelResult: ClientResult = new ClientResult();

    selectedState = null;
    selectedCity = null;
    listState: Uf[] = [];
    listCity: City[] = [];

    constructor(public router: Router,
                public clientService: ClientService) {
        super(router);
    }

    ngOnInit(): void {
        this.onState();
        this.setDatePicker();
        this.onSearch();
    }

    pageChanged(event) {
        this.currentPage = event;
        this.onSearch();
    }

    setDatePicker() {
        this.modelFilter.begin = moment().subtract(15, 'days');
        this.modelFilter.end = moment();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: moment().subtract(15, 'days'),
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.begin = start.format();
            this.modelFilter.end = end.format();
        });
    }

    onSearch() {
        this.modelFilter.page = this.currentPage;
        this.clientService.getClients(this.modelFilter).subscribe({
            next: data => {
                this.modelResult = data as ClientResult;
            }
        });
    }

    onState() {
        this.clientService.getState().subscribe({
            next: data => {
                this.listState = data as Uf[];
            }
            , error: err => super.onError(err)
        });
    }

    onCity(id, select?) {
        if (this.isNullOrUndefined(id)) {
            this.listCity = [];
            this.modelFilter.stateId = undefined;
            this.modelFilter.cityId = undefined;
        } else {
            this.modelFilter.stateId = id;
            this.clientService.getCity(id).subscribe({
                next: data => {
                    this.listCity = data as City[];
                }, error: err => super.onError(err)
            });
        }
    }

    onCitySelect(i) {
        this.selectedCity = i;
        this.modelFilter.cityId = i;
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/report/users-excel?token=${token}`;
        if (!super.isNullOrUndefined(this.modelFilter.begin)) {
            const start = moment(this.modelFilter.begin).format('YYYY-MM-DD');
            url += ('&begin=' + start);
        }
        if (!super.isNullOrUndefined(this.modelFilter.end)) {
            const end = moment(this.modelFilter.end).format('YYYY-MM-DD');
            url += ('&end=' + end);
        }
        if (!super.isNullOrUndefined(this.modelFilter.cpf)) {
            url += ('&cpf=' + this.modelFilter.cpf);
        }
        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }
        if (!super.isNullOrUndefined(this.modelFilter.cityId)) {
            url += ('&cityId=' + this.modelFilter.cityId);
        }
        if (!super.isNullOrUndefined(this.modelFilter.stateId)) {
            url += ('&stateId=' + this.modelFilter.stateId);
        }

        window.open(encodeURI(url));
    }

}
