<section class="container">

    <div class="d-flex align-items-center ml-1 mt-4">
        <svg-icon class="svg" src="assets/svg/user.svg"></svg-icon>
        <h1 class="title ml-4">Usuários</h1>
    </div>

    <div class="mt-3">

        <!--<div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>-->

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="200"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.name"
                                   required />
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="document" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [dropSpecialCharacters]="false"
                                   [clearIfNotMatch]="true"
                                   [(ngModel)]="modelFilter.cpf" required
                                    mask="000.000.000-00"/>
                            <label for="document">CPF</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select name="promoState" id="promoState" class="form-control" [(ngModel)]="selectedState"
                                    (ngModelChange)="onCity(selectedState)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of listState" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="promoState">Estado</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select name="promoCity" id="promoCity" class="form-control" [(ngModel)]="selectedCity"
                                    (ngModelChange)="onCitySelect(selectedCity)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let c of listCity" [ngValue]="c.id">{{c.name}}</option>
                            </select>
                            <label for="promoCity">Cidade</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="currentPage = 1; onSearch();"
                           >
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="align-middle" scope="col">Data Cadastro</th>
                        <th class="align-middle" scope="col">Nome</th>
                        <th class="align-middle" scope="col">CPF</th>
                        <th class="align-middle" scope="col">Sexo</th>
                        <th class="align-middle" scope="col">Data Nascimento</th>
                        <th class="align-middle" scope="col">E-mail</th>
                        <th class="align-middle" scope="col">Telefone</th>
                        <th class="align-middle" scope="col">Estado</th>
                        <th class="align-middle" scope="col">Cidade</th>
                        <th class="align-middle text-center" scope="col">Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="modelResult.results.length === 0">
                        <td colspan="10" class="text-center py-5">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
                        <td>{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                        <td>{{r.name}}</td>
                        <td>{{r.cpf}}</td>
                        <td>{{r.sex}}</td>
                        <td>{{r.birthday | date: 'dd/MM/yyyy'}}</td>
                        <td>{{r.email}}</td>
                        <td>{{r.phone}}</td>
                        <td>{{r.city.uf.name}}</td>
                        <td>{{r.city.name}}</td>
                        <td class="text-center">
                            <a href="#" [routerLink]="'/usuarios-detalhe/' + r.id" class="btn btn-sm btn-outline-info">
                                Detalhe
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>
