<nav id="sidebar">
  <ul class="list-unstyled components navbar-nav" id="accordionExample">
    <!--<div class="">
      <img src="assets/images/logo.png" class="d-block logo" alt="Minha Carreira" />
    </div>-->
    <div class="user d-lg-none">
      <!--<div class="text-center w-100 mb-2">
        <img src="https://via.placeholder.com/30">
      </div>-->
      <span>Olá, <strong>{{model.name}}</strong></span>
    </div>
    <!--<li class="nav-item active">
      <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/document-plus.svg"></svg-icon></span>
        <span class="lbl"> Home</span>
      </a>
    </li>-->

    <!--<li class="nav-item">
      <a href="javascript:void('Dashboard');" [routerLink]="['/']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/dashboard.svg"></svg-icon></span>
        <span class="lbl"> Dashboard</span>
      </a>
    </li>-->

   <!-- <li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/pincode']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/search.svg"></svg-icon></span>
        <span class="lbl"> Consultar Pincode</span>
      </a>
    </li>-->

    <!--<li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/usuarios']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/user.svg"></svg-icon></span>
        <span class="lbl"> Usuários</span>
      </a>
    </li>
    <li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/numbers']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/ball.svg"></svg-icon></span>
        <span class="lbl"> Números da Sorte</span>
      </a>
    </li>-->

    <!--<li class="nav-item help-ts">
      <a href="javascript:void('Ajuda');" class="nav-link" data-toggle="collapse"
         data-target="#help" aria-expanded="false"
         aria-controls="help">
        <span class="ico"><svg-icon src="assets/svg/report.svg"></svg-icon></span>
        <span class="lbl"> Relatórios</span>
      </a>
      <div class="collapse" id="help" data-parent="#accordionExample">
        <a href="javascript:void('Dúvidas');" [routerLink]="['/usuarios']" class="collapse-item cursor text-white">
          <span class="ico empty"></span> Usuários
        </a>
        <a href="javascript:void('Dúvidas');" [routerLink]="['/cupons']" class="collapse-item cursor text-white">
          <span class="ico empty"></span> Cupons
        </a>
        <a href="javascript:void('Dúvidas');" [routerLink]="['/concessionarias']" class="collapse-item cursor text-white">
          <span class="ico empty"></span> Concessionárias
        </a>
      </div>
    </li>-->

    <!--<li class="nav-item">
      <a href="javascript:void('Fale Conosco');" [routerLink]="['/estatisticas']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/line-graph.svg"></svg-icon></span>
        <span class="lbl"> Estatísticas</span>
      </a>
    </li>-->

    <!--<li class="nav-item">
      <a href="javascript:void('Analytics');" [routerLink]="['/analytics']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/analytics.svg"></svg-icon></span>
        <span class="lbl"> Analytics</span>
      </a>
    </li>-->

    <li class="nav-item">
      <a href="javascript:void('Fale Conosco');" [routerLink]="['/fale-conosco']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/contact.svg"></svg-icon></span>
        <span class="lbl"> Fale Conosco</span>
      </a>
    </li>
    <li class="nav-item">
      <a href="javascript:void('Administradores');" [routerLink]="['/administradores']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/admin.svg"></svg-icon></span>
        <span class="lbl"> Administradores</span>
      </a>
    </li>
  </ul>
  <div class="logout d-lg-none">
    <a href="javascript:void('sair');" (click)="onLogout()">
      Sair
    </a>
  </div>

</nav>
