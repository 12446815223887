import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class StatisticsService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getCouponsByState() {
        const url = `${this.BASE_URL}/stats/coupons-by-state`;
        return this.http.get(url);
    }

    getCouponsSummary() {
        const url = `${this.BASE_URL}/stats/coupons`;
        return this.http.get(url);
    }

    getUserByAge() {
        const url = `${this.BASE_URL}/stats/user-by-age`;
        return this.http.get(url);
    }

    getUserByGender() {
        const url = `${this.BASE_URL}/stats/user-by-gender`;
        return this.http.get(url);
    }

    getUserByWeek() {
        const url = `${this.BASE_URL}/stats/coupon-by-week`;
        return this.http.get(url);
    }

    getCityCouponRank() {
        const url = `${this.BASE_URL}/stats/city-coupon-rank`;
        return this.http.get(url);
    }

    getDealershipCouponRank() {
        const url = `${this.BASE_URL}/stats/dealership-coupon-rank`;
        return this.http.get(url);
    }
}
