<section class="container">

    <div class="d-flex align-items-center justify-content-between ml-1 mt-4">
        <div class="d-flex">
            <svg-icon class="svg" src="assets/svg/admin.svg"></svg-icon>
            <h1 class="title ml-4">Administradores</h1>
        </div>

        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-success mx-2 mb-3 mb-xs-0"
           (click)="handlerModal()"
        >
            <svg-icon class="svg-white" src="assets/svg/plus.svg"></svg-icon>
            Adicionar
        </a>
    </div>

    <div class="mt-3">

        <!--<div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>-->

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.name" required />
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="document" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.email" required />
                            <label for="document">E-mail</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="currentPage = 1; onSearch();"
                        >
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>

                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="align-middle w-15" scope="col">Data Cadastro</th>
                            <th class="align-middle" scope="col">Nome</th>
                            <th class="align-middle" scope="col">Email</th>
                            <th class="w-10 align-middle text-center" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="isNullOrUndefined(modelResult.results)">
                            <td colspan="4">
                                <p class="py-5 text-center">
                                    Nenhum registro encontrado.
                                </p>
                            </td>
                        </tr>
                        <tr *ngFor="let r of modelResult.results |
                            paginate: {
                             itemsPerPage: modelResult.pageSize,
                             currentPage: modelResult.currentPage,
                             totalItems: modelResult.totalCount}">
                            <td>{{r.createdAt | date : 'dd/MM/yyyy'}}</td>
                            <td>{{r.name}}</td>
                            <td>{{r.email}}</td>
                            <td class="text-center">
                                <a href="javascript:void('');"  (click)="onDelete(r.id)" class="btn btn-sm btn-danger"
                                   *ngIf="model.id !== r.id"
                                >
                                  <svg-icon class="svg-white w-70" src="assets/svg/delete.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>

    <div class="modal fade" id="addAdminModal" tabindex="-1" role="dialog" aria-labelledby="activitiesModal"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content p-0">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">
                        <span>Cadastrar Novo Admin</span>
                    </h5>
                    <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div class="modal-body px-5">
                    <form action="#" autocomplete="off">
                        <div class="row">
                            <!--<div class="col-12 col-sm-8">
                                <div class="input-material">
                                    <select id="modalProfile" class="form-control" name="type"
                                            [(ngModel)]="modelAdmin.profileId"
                                    >
                                        <option selected [ngValue]="'Admin'">Admin</option>
                                        <option selected [ngValue]="'default'">Padrão</option>
                                    </select>
                                    <label for="modalProfile" class="mb-0 mr-3">Perfil*</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4 text-right">
                                <div class="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input type="checkbox" class="custom-control-input" id="customControlInline"
                                           [(ngModel)]="modelAdmin.status"
                                           [checked]="modelAdmin.status"
                                           (change)="modelAdmin.status = !modelAdmin.status"
                                    >
                                    <label class="custom-control-label" for="customControlInline">Ativo</label>
                                </div>
                            </div>-->
                            <div class="col-12">
                                <div class="input-material">
                                    <input id="modalNome" name="activityNewName" class="form-control"
                                           [(ngModel)]="modelAdmin.name"
                                           type="text"/>
                                    <label for="modalNome">Nome*</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="input-material">
                                    <input id="modalUserAdminEmail" name="modalUserAdminEmail" class="form-control"
                                           [(ngModel)]="modelAdmin.email"
                                           type="text" />
                                    <label for="modalUserAdminEmail">E-mail*</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="input-material">
                                    <input id="modalPassword" name="activityNewName" class="form-control"
                                           [(ngModel)]="modelAdmin.password"
                                           type="password" />
                                    <label for="modalPassword">Senha*</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="input-material">
                                    <input id="modalPasswordConfirm" name="activityNewName" class="form-control"
                                           [(ngModel)]="modelAdmin.passwordConfirm"
                                           type="password" />
                                    <label for="modalPasswordConfirm">Confirmar Senha*</label>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row col-12 d-flex justify-content-center">
                        <div class="col-md-4 my-2 col-12 my-2">
                            <button type="button" class="btn btn-block btn-outline-danger " data-dismiss="modal">Cancelar</button>
                        </div>
                        <div class="col-md-4 col-12 my-2">
                            <button type="button" class="btn btn-block btn-primary" (click)="onSave()">Salvar</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>
