<section class="container">

    <div class="d-flex align-items-center ml-1 mt-4">
        <svg-icon class="svg" src="assets/svg/ball.svg"></svg-icon>
        <h1 class="title ml-4">Cupons</h1>
    </div>

    <div class="mt-3">

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="200"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <input id="filterNumber" class="form-control" type="text" maxlength="200" autocomplete="off"
                                   [(ngModel)]="modelFilter.number" required />
                            <label for="filterNumber">Número</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <select name="promoState" id="promoState" class="form-control" [(ngModel)]="selectedState"
                                    (ngModelChange)="onCity(selectedState)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of listState" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="promoState">Estado</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <select name="promoCity" id="promoCity" class="form-control" [(ngModel)]="selectedCity"
                                    (ngModelChange)="onCitySelect(selectedCity)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let c of listCity" [ngValue]="c.id">{{c.name}}</option>
                            </select>
                            <label for="promoCity">Cidade</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="currentPage = 1; onSearch();"
                        >
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="align-middle w-10" scope="col">Opções</th>
                            <th class="align-middle w-15" scope="col">Data Cadastro</th>
                            <th class="align-middle w-10 text-right" scope="col">Quantidade</th>
                            <th class="align-middle w-10 text-right" scope="col">Total Produtos</th>
                            <th class="align-middle w-10 text-right" scope="col">Total Serviços</th>
                            <th class="align-middle w-10 text-right" scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="modelResult.results.length === 0">
                            <td colspan="8" class="text-center py-5">
                                Nenhum registro encontrado.
                            </td>
                        </tr>
                        <tr  *ngFor="let r of modelResult.results |
                            paginate: {
                             itemsPerPage: modelResult.pageSize,
                             currentPage: modelResult.currentPage,
                             totalItems: modelResult.totalCount}">
                            <td class="text-center">
                                <a href="javascript:void('');" class="btn btn-sm btn-outline-info" (click)="onDetailNf(r.couponNfs)">
                                    Detalhe
                                </a>
                            </td>
                            <td>{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-right">{{r.qty}}</td>
                            <td class="text-right">{{r.totalProduct | currency: 'BRL'}}</td>
                            <td class="text-right">{{r.totalService | currency: 'BRL'}}</td>
                            <td class="text-right">{{r.total | currency: 'BRL'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<div class="modal fade" id="modalDetailNf" tabindex="-1" role="dialog" aria-labelledby="activitiesModal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Notas Fiscais Cupom</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body px-5">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-3" *ngFor="let x of modelDetail">
                        <div class="card">
                            <div class="text-center card-body">
                                <div>
                                    <div class="text-left">
                                        <p class="text-muted d-flex justify-content-between align-items-end border-bottom pb-2">
                                            <strong>Número do cupom: </strong> <span class="ms-2 h4 mb-0">{{x.number}}</span>
                                        </p>
                                        <p class="text-muted"><strong>Tipo: </strong> <span class="ms-2">{{x.type.typeName}}</span></p>
                                        <p class="text-muted"><strong>CPF: </strong> <span class="ms-2">{{x.cpf}}</span></p>
                                        <p class="text-muted"><strong>Data da compra: </strong> <span class="ms-2">{{x.buyedAt | date: 'dd/MM/yyyy'}}</span></p>
                                        <p class="text-muted"><strong>CNPJ da Nota: </strong> <span class="ms-2">{{x.dealership?.cnpj}}</span></p>
                                        <td class="text-muted text-right"><strong>Total: {{x.total | currency: 'BRL'}}</strong> <span class="ms-2"> </span></td>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row col-12 d-flex justify-content-center">
                    <div class="col-md-4 my-2 col-12 my-2">
                        <button type="button" class="btn btn-block btn-outline-primary " data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
