import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class AnalyticsService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getAnalytics(m) {
        const url = `${this.BASE_URL}/analytics`;
        return this.http.post(url, m);
    }

}
