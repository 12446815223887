import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class DealershipService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getDealerships(m: any) {
        const url = `${this.BASE_URL}/report/dealerships`;
        return this.http.post(url, m);
    }

    activateDealership(id: string) {
        const url = `${this.BASE_URL}/report/dealerships-validate/${id}`;
        return this.http.post(url, null);
    }
}
