import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {City, Uf} from '../../model/clients/clients';
import {Router} from '@angular/router';
import {ClientService} from '../../service/client.service';
import {environment} from '../../../environments/environment';
import {DealershipFilter} from '../../model/dealership/dealershipFilter';
import {DealershipResult} from '../../model/dealership/dealership';
import {DealershipService} from '../../service/dealership.service';

@Component({
    selector: 'app-dealership',
    templateUrl: './dealership.component.html',
    styleUrls: ['./dealership.component.scss']
})
export class DealershipComponent extends BaseComponent implements OnInit {

    currentPage = 1;
    modelFilter: DealershipFilter = new DealershipFilter();
    modelResult: DealershipResult = new DealershipResult();

    selectedState = null;
    listState: Uf[] = [];
    listCity: City[] = [];

    constructor(public router: Router,
                public clientService: ClientService,
                public dealershipService: DealershipService) {
        super(router);
    }

    ngOnInit(): void {
        this.onState();
        this.onSearch();
    }

    pageChanged(event) {
        this.currentPage = event;
        this.onSearch();
    }

    onSearch() {
        this.modelFilter.page = this.currentPage;
        this.dealershipService.getDealerships(this.modelFilter).subscribe({
            next: data => {
                this.modelResult = data as DealershipResult;
            }
        });
    }

    onState() {
        this.clientService.getState().subscribe({
            next: data => {
                this.listState = data as Uf[];
            }, error: err => super.onError(err)
        });
    }

    onCity(id) {
        if (this.isNullOrUndefined(id)) {
            this.listCity = [];
            this.modelFilter.state = undefined;
        } else {
            this.modelFilter.state = id;
        }
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/report/dealerships-excel?token=${token}`;
        if (!super.isNullOrUndefined(this.modelFilter.fantasyName)) {
            url += ('&fantasyName=' + this.modelFilter.fantasyName);
        }
        if (!super.isNullOrUndefined(this.modelFilter.state)) {
            url += ('&state=' + this.modelFilter.state);
        }
        if (!super.isNullOrUndefined(this.modelFilter.cnpj)) {
            url += ('&cnpj=' + this.modelFilter.cnpj);
        }
        window.open(encodeURI(url));
    }

    actionActivate(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente ativar este concessionário?', () => {
            this.dealershipService.activateDealership(id).subscribe({
                next: data => {
                    this.onSearch();
                }, error: err => super.onError(err)
            });
        });
    }
}
